import React from 'react'
import Seo from '../../components/Seo'
import DefaultSection from '../../components/DefaultSection'
import Article from '../../components/Article'
import TeaserSection from '../../components/TeaserSection'
import TeaserHeader from '../../components/TeaserHeader'
import TeaserBody from '../../components/TeaserBody'
import BigTitle from '../../components/BigTitle'
import Tiles from '../../components/Tiles'
import Tile from '../../components/Tile'
import JobListItem from '../../components/JobListItem'
import Slide from '../../components/Slide'
import SliderSection from '../../components/SliderSection'
import JobCategory from '../../components/JobCategory'
import IndividualContactSection from '../../components/IndividualContactSection'


const Page = () => {
  return (
    <React.Fragment>
      <Seo title="Karriere"
           description="Werde Teil unseres Teams und entwickel moderne Unternehmenslösungen für unsere Kunden."
      />

      <BigTitle title="Karriere" imageRelativePath={'Titelbild_Karriere.jpg'} className="mask-black" textInverted={true}
                menuInverted={true} logoInverted={true}>
        Werde Teil unseres Teams und entwickle moderne Unternehmenslösungen für unsere Kunden.
      </BigTitle>

      <DefaultSection>
        <Article>
          <h1>Was uns antreibt</h1>
          <p>
            Wir sind ein bunt gemixtes Team aus alten Hasen und jungen Wilden, die leidenschaftlich gern
            Softwarelösungen für unsere Kunden entwickeln und fit für die Zukunft machen. Wir sind engagiert, wenn es
            darum geht, gemeinsam mit unseren Kunden deren Ziele zu erreichen. Wir sind wissensdurstig, probieren Neues
            aus und entwickeln Altbewährtes weiter.
          </p>
        </Article>
      </DefaultSection>

      <DefaultSection className="bg-level-1">
        <Article>
          <h1>Die Aufgaben eines IT-Consultants</h1>
          <p>Der Begriff des IT-Consultants ist oft schwer zu fassen. Deshalb möchten wir einen Blick in unsere
            tägliche Arbeit werfen, um Dir zu zeigen, was wir tun. Als Technologie-Experten wirken wir an großen
            Unternehmensanwendungen mit, indem wir bei unseren
            Kunden:
            <ul>
              <li>einzelne Softwaremodule oder vollständige Anwendungen entwickeln und verantworten</li>
              <li>entscheiden, welche Technologien eingesetzt werden sollen</li>
              <li>bestehende Anwendungslandschaften analysieren und bewerten</li>
              <li>die Architektur der Anwendungen erstellen und umsetzen</li>
            </ul>
          </p>
          <h2>Passt perfekt?</h2>
          <p>
            Wir wünschen uns Kolleginnen und Kollegen, die unsere Leidenschaft für Softwareentwicklung teilen, gerne
            neue Technologien, neue Branchen, neue Menschen kennenlernen möchten, in Java oder einer anderen
            objektorientierten oder funktionalen Sprache erfahren sind, liebend gern über den Tellerrand schauen und
            Teamgeist haben.</p>

          <p>Du musst nicht perfekt sein. Wir sind es auch nicht. Aber wir arbeiten gemeinsam daran, es zu
            werden.</p>
        </Article>
      </DefaultSection>


      <DefaultSection>
        <h1 className="mb-5" id="StellenangeboteAktuell">Aktuelle Stellenangebote</h1>
        <p className="mb-5">Wir sind an ernst gemeinten Bewerbungen interessiert, die sich auf eines unserer Angebote
          beziehen oder initiativ an uns gerichtet sind. Seit einiger Zeit erreichen uns Massen-E-Mails mit Bewerbungen
          auf Stellen, die wir aktuell nicht bieten. Um Ressourcen zu sparen, lassen wir diese unbeantwortet und bitten
          um Verständnis.</p>

        <p className="mb-5"> Freie Stellen im Bereich "Festanstellung" haben wir aktuell leider keine. Gerne kannst Du
          Dich in diesem Bereich initiativ bewerben, z. B. für folgende Positionen:</p>

        <JobCategory title="Festanstellung" />
        <JobListItem linkTo="stellenangebote/it-consultant-qualitaetssicherung-java-m-w-d"
                     jobTitle="IT-Consultant Qualitätssicherung Java (m/w/d)" noBorder />
        <JobListItem linkTo="stellenangebote/it-consultant-web-applications-java-m-w-d"
                     jobTitle="IT-Consultant Web Applications Java (m/w/d)" />
        <JobListItem linkTo="stellenangebote/java-full-stack-entwickler-m-w-d"
                     jobTitle="Java Full Stack Entwickler (m/w/d)" />

        <JobCategory title="Angebote für Studierende" />
        <JobListItem linkTo="stellenangebote/werkstudierende-bereich-grafikdesign-m-w-d"
                     jobTitle="Werkstudierende im Bereich Grafikdesign (m/w/d)" />
        <JobListItem linkTo="stellenangebote/werkstudierende-bereich-marketing-m-w-d"
                     jobTitle="Werkstudierende im Bereich Marketing (m/w/d)" />
        <JobListItem linkTo="stellenangebote/werkstudent-im-bereich-softwareentwicklung-m-w-d"
                     jobTitle="Werkstudierende im Bereich Softwareentwicklung (m/w/d)" />
        <JobListItem linkTo="stellenangebote/berufspraktikum-informatik"
                     jobTitle="Berufspraktikum Informatik" />
        <JobListItem linkTo="stellenangebote/praktikant-im-bereich-softwareentwicklung-m-w-d"
                     jobTitle="Praktikant im Bereich Softwareentwicklung (m/w/d)" />

        <JobListItem linkTo="stellenangebote/studien-und-abschlussarbeiten"
                     jobTitle="Studien- und Abschlussarbeiten" />

        <JobCategory title="Nichts Passendes gefunden?" />
        <p className="mb-5">
          Auch wenn Du auf den ersten Blick nicht ins Stellenprofil passt, sind wir immer auf der Suche nach neuen
          Talenten und klugen Köpfen. Schick uns gerne Deine Initiativbewerbung und verrate uns, warum Du zu BUSCHMAIS
          gehörst!
        </p>
        <p className="mt-5">
          <a className="btn btn-outline-black" href="mailto:jobs@buschmais.com"
             title="Jetzt per Mail bewerben">Jetzt per Mail bewerben</a>
        </p>
      </DefaultSection>

      <SliderSection menuInverted={true} logoInverted={true}>
        <Slide category="Kunden-Projekt" title="Digitalisierung von Geschäftsprozessen eines Energieversorgers"
               keywords="Digitalisierung, Automatisierung, Spring Boot, Angular"
               className="bg-gradient-brand-1">
          <p>
            Dank unserer Arbeit wird z. B. die Terminabstimmung zwischen Kunde und Berater vereinfacht und weitere noch
            auf Fax und Telefon basierte Dienste digitalisiert. (Falko Krische-Dedek)
          </p>
        </Slide>

        <Slide category="Kunden-Projekt" title="Innovatives Produktmanagement eines Online-Händlers"
               keywords="Künstliche Intelligenz, Neo4j, Webentwicklung"
               className="bg-gradient-brand-2">
          <p>
            Wir ermöglichen es, dass sich ein Schweizer Elektronikfachhändler im hart umkämpften Online-Geschäft
            Wettbewerbsvorteile sichert und weiterhin als Marktführer an der Spitze steht. (Peter Süttner)
          </p>
        </Slide>
        <Slide category="Kunden-Projekt" title="Innovation in der Mikroelektronik" keywords="Big Data, IoT"
               className="bg-gradient-brand-3">
          <p>Wir sind Teil eines Entwicklerteams bei dem größten Anbieter von Lithografiesystemen für die
            Halbleiterindustrie und sorgen für die Zukunft im Bereich Mikroelektronik.
            (Ronald Kunzmann)
          </p>
        </Slide>
      </SliderSection>

      <TeaserSection anchor="deshalbBUSCHMAIS">
        <TeaserHeader hasBody title="Deshalb BUSCHMAIS" />
        <TeaserBody>

          <Tiles animateScrollIntoView>
            <Tile className="bg-level-0" title="Fachliche Weiterentwicklung">
              <p>
                Schon allein durch abwechslungsreiche Projekte lernen wir
                immer wieder neue Situationen, Branchen, Systeme,
                Technologien, Probleme und Lösungen kennen und erweitern stets unseren Erfahrungsschatz. Wir stellen uns
                neuen Herausforderungen und wachsen persönlich und technologisch mit unseren Aufgaben.
              </p>
              <p>
                Um auf dem neusten Stand zu bleiben und über den Tellerrand blicken zu können, nehmen wir
                regelmäßig an
                Fachkonferenzen teil, wie z. B. am JUG
                Saxony Day, oder tauschen uns beim Firmenfrühstück aus. Workshops und Schulungen
                sind
                dank der kurzen Kommunikationswege schnell organisiert.
              </p>
            </Tile>

            <Tile className="bg-level-0" title="Individualität">
              <p>
                Wir decken gemeinsam Talente auf und begleiten bei der individuellen Weiterentwicklung, ob Entwickler
                oder Architekt, Krisenmanager oder Koordinator.
              </p>
            </Tile>

            <Tile className="bg-level-0" title="Wissensaustausch">
              <p>Jeder lernt von jedem. Der Austausch untereinander bringt uns alle voran. Neue Sprachen, Frameworks
                oder Methoden werden bei einem spontanen Coaching, bei einer Teamschulung durch einen erfahrenen
                Kollegen oder bei unserem gemeinsamen, monatlichen Frühstück schnell an den Mann oder die Frau
                gebracht.</p>
            </Tile>

            <Tile className="bg-level-0" title="Teamevents">
              <p>Langeweile kommt bei uns nicht auf. Wir veranstalten Ausflüge und Grillabende und nehmen an Sportevents
                teil. Die Weihnachtsfeier bei BUSCHMAIS ist immer ein Highlight und findet zum Wohlgefallen aller
                abseits des Weihnachtstrubels am Jahresanfang statt.</p>
            </Tile>

            <Tile className="bg-level-0" title="Work-Life-Balance">
              <p>Die Vereinbarkeit von Privat- und Berufsleben liegt uns am Herzen. In Abhängigkeit von Aufgaben und
                Projekten kann die Arbeitszeit flexibel gestaltet oder im Homeoffice gearbeitet werden. Wir setzen uns
                für gesundes Arbeiten ein. Hochwertige, ergonomische Arbeitsplätze und Angebote zur Gesundheitsförderung
                sind für uns eine Selbstverständlichkeit.</p>
            </Tile>

            <Tile className="bg-level-0" title="Kurze Kommunikations- und Entscheidungswege">
              <p>Uns sind direkte Absprachen enorm wichtig, um effizient voran zu kommen - ganz gleich ob neue Hardware,
                ein spontanes Meeting oder einfach eine kleine Hilfestellung.</p>
            </Tile>

            <Tile className="bg-level-0" title="Sehr gute Verkehrsanbindung">
              <p>Unser Büro an der Leipziger Straße liegt direkt an der Elbe, unweit des
                Elberadweges und von öffentlichen Verkehrsmitteln.</p>
            </Tile>

            <Tile className="bg-level-0" title="Schmankerl">
              <p>Bei uns verdurstet niemand. Kaffee und Tee aus dem Bioladen und Wasser frisch gefiltert sind
                kostenfrei.</p>
              <p>
                Die Türen stehen offen. Was uns auf der Seele brennt, sprechen wir direkt an.
              </p>
              <p>
                Auch nach Feierabend haben und finden wir ein offenes Ohr - ganz gleich ob privater oder beruflicher
                Gesprächsstoff.
              </p>
            </Tile>
          </Tiles>

        </TeaserBody>
      </TeaserSection>

      <TeaserSection className="bg-level-2">
        <TeaserHeader title="Begeistert?"><p>
          Perfekt. Du kannst Dich bei uns auf offene Stellen, aber
          auch initiativ bewerben. Für Rückfragen stehen wir Dir
          gerne zur Verfügung. Wir freuen uns, von Dir zu hören.</p>
          <p>Bitte beachte, dass Bewerbungen über die E-Mail-Dienste
            Outlook und Gmail aus technischen Gründen ggf. nicht bei uns ankommen.</p>
        </TeaserHeader>
      </TeaserSection>
      <IndividualContactSection personKey={'christiane'} email={'jobs@buschmais.com'} tel={'+49 351 320923 11'}
                                className={'bg-level-1'} />
      <DefaultSection className="bg-warning">
        <Article>
          <h2>Hinweis für Personalvermittler</h2>
          <p>Bitte senden Sie Ihre Anfragen <strong className="text-uppercase"><u>nicht</u></strong> an
            jobs@buschmais.com. Diese Adresse ist ausschließlich für Bewerber*innen gedacht. Anfragen von
            Personalvermittlern an diese Adresse werden von uns ausnahmslos blockiert.</p>
        </Article>
      </DefaultSection>
    </React.Fragment>
  )
}

export default Page
